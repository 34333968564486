<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modalPolicy"
      hide-footer
      centered
      @hidden="hide"
    >
      <div class="mb-4 scroll-policy">
        <div class="mt-3" v-html="policy"></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      showModal: false,
      policy: ""
    };
  },
  async created() {},
  methods: {
    async getPolicy() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/DSR/privacy_policy`)
        .then(async data => {
          if (data.result === 1) {
            this.policy = data.detail;
          }
        });
    },
    async show() {
      this.showModal = true;
      await this.getPolicy();
      this.$emit("show");
    },
    hide() {
      this.showModal = false;
      this.$emit("hide");
    }
  }
};
</script>

<style rel="scss" scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}

.modal-body {
  margin: auto;
  text-align: center;
}
.class-icon-black {
  width: 70px;
}

.scroll-policy {
  overflow-y: visible;
  overflow-x: hidden;
  height: 100vh;
}

.ft-underline {
  text-decoration: underline;
}
::v-deep table {
  border-collapse: collapse;
  width: 100%;
}

::v-deep td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

::v-deep tr:nth-child(even) {
  background-color: #dddddd;
}
::v-deep .close {
  font-size: 3.5rem;

  right: 40px;
  top: 10px;
}
</style>
