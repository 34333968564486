<template>
  <div class="mt-3">
    <label
      >{{ label }}
      <span v-if="required === 1 ? true : false" class="text-error"
        >*</span
      ></label
    >
    <div
      :class="[isValidate ? 'border-error input-container' : 'input-container']"
    >
      <datetime
        type="datetime"
        class="date-picker"
        :input-style="styleDatetime"
        v-model="datetime"
        placeholder="DD/MM/YYYY (HH:MM)"
        format="dd/MM/yyyy (HH:mm)"
        value-zone="Asia/Bangkok"
        ref="datetimeField"
      >
      </datetime>
      <div
        :class="'icon-container text-right'"
        @click="$refs.datetimeField.isOpen = true"
      >
        <font-awesome-icon
          icon="calendar-alt"
          :class="'pointer'"
          :color="$store.state.theme.themePrimaryColor"
        />
      </div>
    </div>
    <div v-if="isValidate" class="text-error ft-14 mt-1">กรุณากรอกข้อมูล</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: true,
      type: String
    },
    required: {
      required: false,
      type: Number
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    fieldtype: {
      required: true,
      type: Number
    },
    questionId: {
      required: true,
      type: Number
    },
    value: {
      required: false,
      type: String
    }
  },
  watch: {
    datetime() {
      this.$emit("handleDatetime", {
        answer_id: 0,
        answer: this.datetime,
        type: this.fieldtype,
        questionId: this.questionId
      });
    }
  },
  data() {
    return {
      datetime: this.value,
      styleDatetime: {
        width: "100%",
        border: "none"
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  padding: 5px 10px;
  border: 1px solid #d8dbe0;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
}
.icon-container {
  flex: 1 1 auto;
  cursor: text;
}
.text-error {
  color: red;
}
.ft-14 {
  font-size: var(--text-md);
}
.border-error {
  border-color: red;
}
</style>
