<template>
  <div>
    <div v-for="(item, index) in customField" :key="index">
      <!-- field type -->
      <b-row v-if="item.field_type_id === 1">
        <b-col>
          <CustomField
            :value="
              item.answer_list.length > 0 ? item.answer_list[0].answer : ''
            "
            :placeholder="item.question"
            :label="item.question"
            @handleChangeValue="handleChangeValue"
            :fieldtype="item.field_type_id"
            :questionId="item.question_id"
            :isValidate="
              v.questionList.$each[index].answer_list.$each[0].answer.$error
            "
            :v="v.questionList.$each[index].answer_list.$each[0].answer"
            :required="item.required"
          />
        </b-col>
      </b-row>
      <!-- checkbox type -->
      <b-row v-if="item.field_type_id === 2">
        <b-col>
          <CustomCheckbox
            :options="item.choice_list"
            :label="item.question"
            :fieldtype="item.field_type_id"
            :questionId="item.question_id"
            @handleChangeCheckbox="handleChangeCheckbox"
            :isValidate="
              v.questionList.$each[index].answer_list.$each[0].answer.$error
            "
            :v="v.questionList.$each[index].answer_list.$each[0].answer"
            :required="item.required"
          />
        </b-col>
      </b-row>
      <!-- dropdown type -->
      <b-row v-if="item.field_type_id === 4">
        <b-col>
          <CustomDropdown
            :options="item.choice_list"
            :label="item.question"
            :fieldtype="item.field_type_id"
            :questionId="item.question_id"
            @handleChangeDropdown="handleChangeDropdown"
            :isValidate="
              v.questionList.$each[index].answer_list.$each[0].answer.$error
            "
            :required="item.required"
          />
        </b-col>
      </b-row>
      <!-- multiple type -->
      <b-row v-if="item.field_type_id === 3">
        <b-col>
          <CustomMultipleChoice
            :options="item.choice_list"
            :label="item.question"
            :fieldtype="item.field_type_id"
            :questionId="item.question_id"
            @handleChangeMultipleChoice="handleChangeMultipleChoice"
            valueField="choice_id"
            textField="choice"
            :isValidate="
              v.questionList.$each[index].answer_list.$each[0].answer.$error
            "
            :required="item.required"
          />
        </b-col>
      </b-row>
      <!-- datetime -->
      <b-row v-if="item.field_type_id === 5">
        <b-col>
          <CustomDatetime
            :value="
              item.answer_list.length > 0 ? item.answer_list[0].answer : ''
            "
            :label="item.question"
            :required="item.required"
            :isValidate="
              v.questionList.$each[index].answer_list.$each[0].answer.$error
            "
            :fieldtype="item.field_type_id"
            :questionId="item.question_id"
            @handleDatetime="handleDatetime"
          />
        </b-col>
      </b-row>
      <!-- branch dropdown -->
      <b-row v-if="item.field_type_id === 6">
        <b-col>
          <CustomDropdown
            :options="item.choice_list"
            :label="item.question"
            :fieldtype="item.field_type_id"
            :questionId="item.question_id"
            @handleChangeDropdown="handleChangeDropdown"
            :isValidate="
              v.questionList.$each[index].answer_list.$each[0].answer.$error
            "
            :required="item.required"
          />
        </b-col>
      </b-row>
      <!-- Date -->
      <b-row v-if="item.field_type_id === 7">
        <b-col>
          <CustomDate
            :value="
              item.answer_list.length > 0 ? item.answer_list[0].answer : ''
            "
            :label="item.question"
            :required="item.required"
            :isValidate="
              v.questionList.$each[index].answer_list.$each[0].answer.$error
            "
            :fieldtype="item.field_type_id"
            :questionId="item.question_id"
            @handleDate="handleDate"
          />
        </b-col>
      </b-row>
      <!-- Image -->
      <b-row v-if="item.field_type_id === 8">
        <b-col>
          <CustomImageField
            v-if="!isLoadingImage"
            :img="questionList[index]"
            :label="item.question"
            :required="item.required"
            :fieldtype="item.field_type_id"
            :questionId="item.question_id"
            @onChangeImage="onChangeImage"
            @deleteImage="deleteImage"
            :isValidate="
              v.questionList.$each[index].answer_list.$each[0].answer.$error
            "
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import CustomField from "@/components/custom-fields/fields/CustomField";
import CustomCheckbox from "@/components/custom-fields/fields/CustomCheckbox";
import CustomDropdown from "@/components/custom-fields/fields/CustomDropdown";
import CustomMultipleChoice from "@/components/custom-fields/fields/CustomMultipleChoice";
import CustomDatetime from "@/components/custom-fields/fields/CustomDatetime";
import CustomDate from "@/components/custom-fields/fields/CustomDate";
import CustomImageField from "@/components/custom-fields/fields/CustomImageField";
export default {
  components: {
    CustomField,
    CustomCheckbox,
    CustomDropdown,
    CustomMultipleChoice,
    CustomDatetime,
    CustomDate,
    CustomImageField
  },
  props: {
    customField: {
      required: true,
      type: Array
    },
    v: {
      required: true,
      type: Object
    },
    questionList: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      images: "",
      isLoadingImage: false,
      isDisable: false
    };
  },
  methods: {
    handleChangeValue(data) {
      const findQuestionId = this.questionList.findIndex(
        el => el.question_id === data.questionId
      );
      this.questionList[findQuestionId].answer_list[0].answer = data.answer;
    },
    handleChangeCheckbox(data) {
      const findId = this.questionList.findIndex(
        el => el.question_id === data.questionId
      );
      if (data.answer.length > 0) {
        this.questionList[findId].answer_list = data.answer;
      } else {
        this.questionList[findId].answer_list[0].answer = "";
        this.questionList[findId].answer_list[0].answer_id = 0;
      }
    },
    handleChangeDropdown(data) {
      const findId = this.questionList.findIndex(
        el => el.question_id === data.questionId
      );
      this.questionList[findId].answer_list[0].answer = data.answer;
      this.questionList[findId].answer_list[0].answer_id = data.answer_id;
    },
    handleChangeMultipleChoice(data) {
      const findId = this.questionList.findIndex(
        el => el.question_id === data.questionId
      );
      this.questionList[findId].answer_list[0].answer = data.answer;
      this.questionList[findId].answer_list[0].answer_id = data.answer_id;
    },
    handleDatetime(data) {
      const findId = this.questionList.findIndex(
        el => el.question_id === data.questionId
      );
      this.questionList[findId].answer_list[0].answer = data.answer;
      this.questionList[findId].answer_list[0].answer_id = data.answer_id;
    },
    handleDate(data) {
      const findId = this.questionList.findIndex(
        el => el.question_id === data.questionId
      );
      this.questionList[findId].answer_list[0].answer = data.answer;
      this.questionList[findId].answer_list[0].answer_id = data.answer_id;
    },
    onChangeImage(data) {
      const findId = this.questionList.findIndex(
        el => el.question_id === data.questionId
      );
      this.questionList[findId].answer_list[0].answer = data.answer;
      this.questionList[findId].answer_list[0].answer_id = data.answer_id;
    },
    deleteImage(data) {
      const findId = this.questionList.findIndex(
        el => el.question_id === data.questionId
      );
      this.questionList[findId].answer_list[0].answer = "";
      this.questionList[findId].answer_list[0].answer_id = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>