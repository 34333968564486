<template>
  <div class="mt-3">
    <label
      >{{ label }}
      <span v-if="required === 1 ? true : false" class="text-error"
        >*</span
      ></label
    >
    <b-form-select
      :class="[isValidate ? 'border-error' : '']"
      v-model="selected"
      :options="options"
      value-field="choice_id"
      text-field="choice"
      @change="handleChangeValue"
    ></b-form-select>
    <div v-if="isValidate" class="text-error ft-14 mt-1">กรุณาเลือกข้อมูล</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: true,
      type: String
    },
    options: {
      required: true,
      type: Array
    },
    fieldtype: {
      required: true,
      type: Number
    },
    questionId: {
      required: true,
      type: Number
    },
    required: {
      required: true,
      type: Number
    },
    isValidate: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      selected: null
    };
  },
  created() {
    this.getAnswer();
  },
  methods: {
    getAnswer() {
      for (const choice of this.options) {
        if (choice.check) {
          this.selected = choice.choice_id;
          break;
        } else {
          if (choice.is_default) {
            this.selected = choice.choice_id;
          }
        }
      }
    },
    handleChangeValue(value) {
      const findValue = this.options.find(el => el.choice_id === value);
      this.$emit("handleChangeDropdown", {
        answer_id: value,
        answer: findValue.choice,
        type: this.fieldtype,
        questionId: this.questionId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.text-error {
  color: red;
}
.ft-14 {
  font-size: var(--text-md);
}
.border-error {
  border-color: red;
}
</style>
